import axios from "axios";
import store from "@/store";
import { getAuth } from "firebase/auth";
import { EventBus } from '@/plugins/eventbus.js';
import i18n from '@/i18n'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

const http = axios.create({
    baseURL: process.env.VUE_APP_API_HOST
});

// Add global headers
http.interceptors.request.use(async config => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    // this either gets the current token or refreshes it to retrieve a new token
    var token = await user.getIdToken();
    config.headers.authorization = `Bearer ${token}`
  }
  else {
    if (store.getters.localToken) {
      config.headers.authorization = `Bearer ${store.getters.localToken}`
    }
  }

  // set the tenant
  config.headers["x-tenant-id"] = tenant.id;
  
  if (process.env.NODE_ENV !== 'production') {
    //config.headers["x-tenant-id"] = '';
  }
  if (i18n.locale) {
    config.params = config.params || {};
    config.params['lang'] = i18n.locale;
  }

  return config
 }, (error) => {
  return Promise.reject(error)
});

export default {
    http: http,
    enableInterceptor() {
        this.axiosInterceptor = http.interceptors.request.use((config) => {
          store.commit('START_LOADING');
        return config;
      }, (error) => {
        console.log('HTTP Request error', error);
        store.commit('STOP_LOADING');
        return Promise.reject(error);
      });

      http.interceptors.response.use((response) => {
        store.commit('STOP_LOADING');
        return response;
      }, function (error) {
        store.commit('STOP_LOADING');
        if (error.request) {
          EventBus.$emit('http-error', error.request);
        }
        //console.log('HTTP Response error', error.request.status, error);
        return Promise.reject(error);
      });
    },

    disableInterceptor() {
      http.interceptors.request.eject(this.axiosInterceptor);
    }
};
