<template>
<div v-if="menu">
    <template v-for="(item, i) in menu">

        <v-menu v-if="item.items && item.items.length" :key="i" offset-y :min-width="item.items.some(x => x.multi_column) ? 600 : 0">
          <template v-slot:activator="{ on }">
            <v-btn text class=" ms-0" v-on="on">
              {{ item.title }}
              <v-icon color="" class="ms-1">fal fa-angle-down</v-icon>
            </v-btn>
          </template>
          <v-card :set="submenus = item.items.filter(x => x.multi_column && x.items && x.items.length)" :set2="nonsubmenus = item.items.filter(x => !x.multi_column)">
            <v-container v-if="submenus.length" class="pb-0">
            <v-row>
                <v-col :cols="12 / submenus.length" v-for="(submenu, subi) in submenus" :key="subi">
                    <h4>
                      <router-link v-if="submenu.path" class="no-decoration" :to="submenu.path">{{submenu.title}}</router-link>
                      <span v-else>{{submenu.title}}</span>
                    </h4>
                    <!-- <h4><router-link class="no-decoration" :href="submenu.path">{{submenu.title}}</router-link></h4> -->
                    <v-list class="pb-0">
                    <template v-for="(subitem, i) in submenu.items">
                        <v-list-item :key="i" link :to="isAbsoluteUrl(subitem.path) ? null : subitem.path" :href="isAbsoluteUrl(subitem.path) ? subitem.path : null" exact class="px-0">
                          <v-list-item-icon v-if="subitem.icon" class="me-4">
                            <v-icon>fal {{ subitem.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                              <v-list-item-title>
                              {{ subitem.title }}
                              </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- <v-divider />
                    <v-list-item link class="px-0" :href="submenu.path">View All</v-list-item> -->
                    </v-list>
                </v-col>
            </v-row>
            <v-divider v-if="nonsubmenus.length" />
            </v-container>
            <v-list v-if="nonsubmenus.length">
            <template v-for="(subitem, i) in nonsubmenus">
                <v-list-item :key="i" link exact :to="isAbsoluteUrl(subitem.path) ? null : subitem.path" :href="isAbsoluteUrl(subitem.path) ? subitem.path : null">
                  <v-list-item-icon v-if="subitem.icon" class="me-4">
                    <v-icon>fal {{ subitem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="subitem.markdown" v-html="$options.filters.markdown(subitem.markdown)"/>
                        <span v-else >{{ subitem.title }}</span>
                      </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>
            </v-list>

          </v-card>
        </v-menu>  
        <v-btn v-else :key="i" :to="isAbsoluteUrl(item.path) ? null : item.path" :href="isAbsoluteUrl(item.path) ? item.path : null" text>{{ item.title }}</v-btn>  
    </template>
    </div> 
</template>

<script>
import { mapGetters } from "vuex";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
import menuJson from "@/data/cms/nav-menu-cofi.json";

export default {
  name: "CmsMenu",
  props: {
  },
  data() {
    return {
      menu: null,
      tenant: tenant,
    };
  },
  mounted() {
    this.menu = menuJson;//await cmsService.getData('nav-menu-cofi');
  },
  methods: {
    isAbsoluteUrl(path) { return path && path.startsWith('https://'); }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style scoped>
    i.v-icon { max-width: 24px; }
</style>