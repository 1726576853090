const providers = ['garmin', 'strava', 'fitbit', 'polar', 'oura', 'whoop', 'suunto', 'coros', 'underarmour', 'eventscom', 'concept2', 'asdeporte', 'slack', 'microsoft'];
const remote_syncable_providers = ['garmin', 'strava', 'fitbit', 'polar', 'oura', 'whoop', 'suunto', 'coros', 'underarmour', 'concept2' ];
const auto_redirect_providers = ['eventscom', 'asdeporte', 'coros', 'suunto', 'polar', 'slack'];

import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {

    shouldAutoRedirect(provider) {
        return auto_redirect_providers.includes(provider);
    },
    canSyncRemotely(provider) {
        return remote_syncable_providers.includes(provider);
    },
    getSupportedProviders() {
        return ['Garmin', 'Strava', 'Under Armour (MapMyRun, ...)', 'Fitbit', 'Polar', 'Suunto', 'COROS']
    },
    hasConnection(profile) {
        return profile && profile.providers && profile.providers.length > 0;
    },
    isConnected(profile, provider) {
        return profile && profile.providers && profile.providers.indexOf(provider) >= 0;
    },
    hasAllConnections(profile) {
        return providers.every(x => this.isConnected(profile, x));
    },

    getProviderUrls(options, event) {
        options = this.mergeOptions(options);
        return {
            strava: this.stravaConnectUrl('read,activity:read_all', options, event),
            strava_all: this.stravaConnectUrl('read,activity:read_all', options, event),
            garmin: this.garminConnectUrl(options),
            fitbit: this.fitbitConnectUrl(options),
            polar: this.polarConnectUrl(options),
            suunto: this.suuntoConnectUrl(options),
            coros: this.corosConnectUrl(options),
            underarmour: this.underarmourConnectUrl(options),
            eventscom: this.eventsComConnectUrl(options),
            concept2: this.concept2ConnectUrl(options),
            asdeporte: this.asdeporteConnectUrl(options),
            oura: this.ouraConnectUrl(options),
            whoop: this.whoopConnectUrl(options),
            huawei: this.huaweiConnectUrl(options),
            slack: this.slackConnectUrl({mode: 'force-login', ...options}),
            microsoft: this.microsoftConnectUrl({mode: 'force-login', ...options}),
        };
    },

    mergeOptions(options) {
        return {
            ...{
                tenant: tenant.id,
                referral: null,
                eventId: null,
                orgId: null,
                verificationCode: null,
                profileId: null,
                viewMode: null,
            },
            ...options,
        };
    },

    stravaConnectUrl(scope, options, event) {
        // try get from event.strava_app_id
        var clientId = process.env.VUE_APP_STRAVA_CLIENT_ID;
        var redirectUrl = process.env.VUE_APP_STRAVA_REDIRECT_URL;
        if (tenant.stravaClientId) {
            clientId = tenant.stravaClientId;
            redirectUrl = "https://api.sodisp.com/open/v1.0/oauth/strava"; // must be from api.sodisp.com domain
        }
        if (event && event.oauth_client_ids && event.oauth_client_ids.strava) {
            clientId = event.oauth_client_ids.strava;
            redirectUrl = "https://api.sodisp.com/open/v1.0/oauth/strava"; // must be from api.sodisp.com domain
        }
        return `http://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUrl}&approval_prompt=auto&scope=${scope}&state=${encodeURIComponent(JSON.stringify(options))}`;
        //return `http://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=https://api.sodisp.com/open/v1.0/oauth/strava&approval_prompt=auto&scope=${scope}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    garminConnectUrl(options) {
        return `${process.env.VUE_APP_GARMIN_CONNECT_URL}?tenant=${options.tenant}&referral=${options.referral || ''}&eventId=${options.eventId || ''}&orgId=${options.orgId || ''}&verificationCode=${options.verificationCode || ''}&view=${options.view || ''}&profileId=${options.profileId || ''}`;
    },
    fitbitConnectUrl(options) {
        return `https://www.fitbit.com/oauth2/authorize?client_id=${process.env.VUE_APP_FITBIT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_FITBIT_REDIRECT_URL}&scope=activity profile location&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    polarConnectUrl(options) {
        return `https://flow.polar.com/oauth2/authorization?client_id=${process.env.VUE_APP_POLAR_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_POLAR_REDIRECT_URL}&scope=accesslink.read_all&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    suuntoConnectUrl(options) {
        return `https://cloudapi-oauth.suunto.com/oauth/authorize?client_id=${process.env.VUE_APP_SUUNTO_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_SUUNTO_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    underarmourConnectUrl(options) {
        return `https://www.mapmyfitness.com/v7.1/oauth2/uacf/authorize/?client_id=${process.env.VUE_APP_UNDERARMOUR_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_UNDERARMOUR_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    corosConnectUrl(options) {
        return `${process.env.VUE_APP_COROS_CONNECT_URL}?client_id=${process.env.VUE_APP_COROS_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_COROS_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    eventsComConnectUrl(options) {
        const scope = 'openid+profile+email+refreshToken';
        return `${process.env.VUE_APP_EVENTSCOM_CONNECT_URL}?client_id=${process.env.VUE_APP_EVENTSCOM_CLIENT_ID}&scope=${scope}&response_type=code&redirect_uri=${process.env.VUE_APP_EVENTSCOM_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    eventsComOrgConnectUrl(options) {
        const scope = 'openid+profile+email+refreshToken+VIEW_ORGANIZATION_DETAILS+VIEW_ORDER_REGISTRATION_REPORTS+EDIT_EVENT';
        return `${process.env.VUE_APP_EVENTSCOM_CONNECT_URL}?client_id=${process.env.VUE_APP_EVENTSCOM_ORG_CLIENT_ID}&scope=${scope}&response_type=code&redirect_uri=${process.env.VUE_APP_EVENTSCOM_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    concept2ConnectUrl(options) {
        const scope = 'user:read,results:read';
        return `${process.env.VUE_APP_CONCEPT2_CONNECT_URL}?client_id=${process.env.VUE_APP_CONCEPT2_CLIENT_ID}&scope=${scope}&response_type=code&redirect_uri=${process.env.VUE_APP_CONCEPT2_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    asdeporteConnectUrl(options) {
        const scope = 'identity';
        return `${process.env.VUE_APP_ASDEPORTE_CONNECT_URL}?client_id=${process.env.VUE_APP_ASDEPORTE_CLIENT_ID}&scope=${scope}&redirect_to=${process.env.VUE_APP_ASDEPORTE_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    ouraConnectUrl(options) {
        return `${process.env.VUE_APP_OURA_CONNECT_URL}&response_type=code&redirect_uri=${process.env.VUE_APP_OURA_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    whoopConnectUrl(options) {
        return `https://api.prod.whoop.com/oauth/oauth2/auth?client_id=${process.env.VUE_APP_WHOOP_CLIENT_ID}&scope=${encodeURIComponent('offline read:cycles read:workout read:profile')}&response_type=code&redirect_uri=${encodeURIComponent(process.env.VUE_APP_WHOOP_REDIRECT_URL)}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    huaweiConnectUrl(options) {
        // Huawei has stupid constraints on the state param and doesn't allow most text
        // State passed back in the URI that is called back when a user's browser is redirected to redirect_uri. This parameter should match the regular expression ^[0-9a-zA-Z:/\.]+$, and is advised to contain up to 100 characters. It is recommended that this parameter be set to a character string constructed by a high-quality random number generator, or set to a hash string that is generated by using a key stored in the backend to sign some session status variables. According to the OAuth 2.0 protocol, you are advised to use the state parameter to prevent CSRF attacks.
        // https://developer.huawei.com/consumer/en/doc/development/HMSCore-Guides/open-platform-oauth-0000001053629189
        const stateHuaweiEncoded = `eventId:${options.eventId||''}/orgId:${options.orgId||''}/profileId:${options.profileId||''}/referral:${options.referral||''}/view:${options.view||''}/mode:${options.mode||''}/tenant:${options.tenant||''}/verificationCode:${options.verificationCode||''}`
        return `${process.env.VUE_APP_HUAWEI_CONNECT_URL}?response_type=code&access_type=offline&client_id=${process.env.VUE_APP_HUAWEI_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_HUAWEI_REDIRECT_URL}&scope=${process.env.VUE_APP_HUAWEI_SCOPE}&state=${stateHuaweiEncoded}`;
    },
    slackConnectUrl(options) {
        return `${process.env.VUE_APP_SLACK_CONNECT_URL}&redirect_uri=${process.env.VUE_APP_SLACK_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
    microsoftConnectUrl(options) {
        return `${process.env.VUE_APP_MICROSOFT_CONNECT_URL}?client_id=${process.env.VUE_APP_MICROSOFT_CLIENT_ID}&scope=${encodeURIComponent(process.env.VUE_APP_MICROSOFT_SCOPE)}&response_type=code&redirect_uri=${process.env.VUE_APP_MICROSOFT_REDIRECT_URL}&state=${encodeURIComponent(JSON.stringify(options))}`;
    },
}
