<template>
  <div v-if="!isEmbeddedInApp" id="footer">
    <v-footer
      v-if="!isEmbedded && tenant.id == 'fitmap'"
      dark
      padless
    >
      <v-row
        justify="center"
        no-gutters
        
      >
        <v-col cols="12" class="footer-row text-center">
          <v-btn
            v-for="(item, i) in $store.state.nav['footer-default']"
            :key="i"
            :to="item.to"
            color="white"
            text
            rounded
            class="my-2"
          >
            {{ item.text_key ? $t('nav.header.' + item.text_key) : item.text }}
          </v-btn>
        </v-col>
        <v-col 
          class="copyright-container py-4 text-center white--text footer-row d-flex flex-row align-center justify-center flex-wrap"
          style=""
          cols="12"
        >
          <img src="https://sodisp.imgix.net/web/fitmap/logo-fitmap-onblack-v2.svg" class="mx-2" width="100" height="58" alt="Logo FitMap" />
          <span class="mx-2"> </span>
          &copy; {{ new Date().getFullYear() }}&nbsp;<a href="https://fitmap.app">FitMap.app</a>
          <span class="mx-2">•</span>
          <router-link to="/privacypolicy" class="white--text">{{$t('nav.footer.privacy')}}</router-link>
          <span class="mx-2">•</span>
          <!-- <router-link to="/cookiepolicy" class="white--text">{{$t('nav.footer.cookies')}}</router-link>
          | -->
          <router-link to="/termsofservice" class="white--text">{{$t('nav.footer.terms-of-service')}}</router-link>
          <span class="mx-2"> </span>
          <LanguageMenu/>

        </v-col>
      </v-row>
    </v-footer>
    <v-footer
      v-else-if="!isEmbedded && tenant.isDefault"
      dark
      padless
    >
      <v-row
        justify="center"
        no-gutters
        
      >
        <v-col cols="12" class="footer-row">
          <v-btn
            v-for="(item, i) in $store.state.nav['footer-default']"
            :key="i"
            :to="item.to"
            color="white"
            text
            rounded
            class="my-2"
          >
            {{ item.text_key ? $t('nav.header.' + item.text_key) : item.text }}
          </v-btn>
        </v-col>
        <v-col 
          class="copyright-container py-4 text-center white--text footer-row"
          cols="12"
        >
          <img src="https://sodisp.imgix.net/web/logo-black.svg" class="float-left mx-2" width="100" height="58" alt="Logo sodisp" />
          &copy; {{ new Date().getFullYear() }} — <a href="https://sodisp.com">sodisp.com</a>
          <div>
          <router-link to="/privacypolicy" class="white--text">{{$t('nav.footer.privacy')}}</router-link>
          |
          <!-- <router-link to="/cookiepolicy" class="white--text">{{$t('nav.footer.cookies')}}</router-link>
          | -->
          <router-link to="/termsofservice" class="white--text">{{$t('nav.footer.terms-of-service')}}</router-link>
          |
          <LanguageMenu/>

          </div>
        </v-col>
      </v-row>
    </v-footer>

    <v-footer
      v-else-if="tenant.id === 'cofi' || tenant.id === 'mzg'"
      class="custom-footer"
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          v-for="(item, i) in $store.state.nav['footer-cofi']"
          :key="i"
          :to="item.to"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ item.text_key ? $t('nav.header.' + item.text_key) : item.text }}
        </v-btn>
        <v-btn outlined color="primary" :to="{name: 'startTrial'}" class="my-2">{{ $t('nav.header.start-trial') }}</v-btn>
        <v-col class="copyright-container py-4 text-center" cols="12">
          {{ $t('nav.footer.download-app') }}
        </v-col>
        <v-col cols="6" style="display:flex;flex-direction:column;align-items:flex-end;">
          <a class="mr-2" href='https://apps.apple.com/app/connected-corporate-fitness/id1594403776' target="_blank"><img style="max-width:120px;" alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png'/></a>
        </v-col>
        <v-col cols="6">
          <a class="ml-2" href='https://play.google.com/store/apps/details?id=app.corporatefitness' target="_blank"><img style="max-width:150px; margin: -9px;" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        </v-col>
        <v-col 
          class="copyright-container py-4 text-center"
          cols="12"
        >
          &copy; {{ new Date().getFullYear() }} — <a :href="tenant.homeUrl">{{tenant.name}}</a>
          <div>
            <a data-cc="c-settings" class="">{{$t('nav.footer.cookies')}}</a>
            |
            <router-link to="/privacypolicy" class="">{{$t('nav.footer.privacy')}}</router-link>
            |
            <!-- <router-link to="/cookiepolicy" class="">{{$t('nav.footer.cookies')}}</router-link>
            | -->
            <router-link to="/termsofservice" class="">{{$t('nav.footer.terms-of-service')}}</router-link>
            <LanguageMenu color="black" style="color:black !important;" class="ms-4"/>
            <v-btn :value="useHighContrast" @click="toggleHighContrast" title="Toggle High Contrast Mode" outlined :color="useHighContrast ? 'primary' : ''" class="ms-4">
              <v-icon class="me-2">fa-adjust</v-icon> {{ $t('nav.footer.contrast-mode') }}
            </v-btn>

          </div>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer
      v-else-if="!tenant.isDefault"
      class="custom-footer"
      padless
    >
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(link, i) in (tenant.footerNavKey ? $store.state.nav[tenant.footerNavKey] : $store.state.nav.footer_embedded)"
          :key="i"
          :to="link.to"
          color="primary"
          text
          rounded
          class="my-2 nav-item"
        >
          {{ link.text_key ? $t('nav.header.' + link.text_key) : link.text }}
        </v-btn>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="8" class="py-4 px-4 powered-by">
          <h4>Powered by <a href="https://fitmap.app" target="_blank">FitMap - Events and Challenges</a></h4>
          FitMap is the most powerful platform to host endurance sports events and challenges. We offer integration with most popular fitness trackers, various challenge modes and numerous powerful features to create engaging in-person, hybrid, and virtual experiences! <a href="https://fitmap.app" class="" target="_blank">Learn how  &raquo;</a>
        </v-col>
        <v-col cols="12" md="4" class="py-4 px-4 d-flex flex-column justify-start align-start align-md-end powered-by">
          <h4>Powered by</h4>
          <a href="https://fitmap.app" target="_blank"><img src="https://sodisp.imgix.net/web/logo-fitmap.svg" class="mx-2 my-2" style="max-width:100px;width:100px;" alt="Logo FitMap"/></a>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col 
          class="py-4 px-4 text-center legal-info d-flex flex-column flex-sm-row align-center"
          cols="12"
        >
          <span>{{ new Date().getFullYear() }} — <a href="https://fitmap.app" target="_blank">FitMap</a></span>
          <v-spacer/>
          <div>
            <router-link to="/privacypolicy" class="">{{$t('nav.footer.privacy')}}</router-link>
            |
            <!-- <router-link to="/cookiepolicy" class="">{{$t('nav.footer.cookies')}}</router-link>
            | -->
            <router-link to="/termsofservice" class="">{{$t('nav.footer.terms-of-service')}}</router-link>
          </div>
          <v-spacer/>
          <LanguageMenu style="color:black !important;"/>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer
      v-else-if="isEmbedded"
      padless
      class="py-4"
    >
      <v-row no-gutters>
        <v-col cols="12" class="px-4 powered-by d-flex flex-column flex-sm-row align-center">
          <h4>Powered by <a :href="tenant.homeUrl" target="_blank">{{ tenant.title }}</a></h4>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col 
          class="px-4 text-center legal-info d-flex flex-column flex-sm-row align-center"
          cols="12"
        >
          <span>&copy; {{ new Date().getFullYear() }} — <a :href="tenant.homeUrl" target="_blank">{{ tenant.name }}</a></span>
          <v-spacer/>
          <div>
            <router-link to="/privacypolicy" class="">{{$t('nav.footer.privacy')}}</router-link>
            |
            <!-- <router-link to="/cookiepolicy" class="">{{$t('nav.footer.cookies')}}</router-link>
            | -->
            <router-link to="/termsofservice" class="">{{$t('nav.footer.terms-of-service')}}</router-link>
          </div>
          <v-spacer/>
          <LanguageMenu style="color:black !important;"/>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<style lang="scss">

  /* CLS improvements:  */
  #footer { 
    min-height: 144px; height: 144px;
    .footer-row { height: 52px; }
    .footer-row.copyright-container { height: 92px; }
  }

  @media (max-width: 500px) {
    #footer { 
      min-height: 272px; height: 272px;
      .footer-row { height: 156px; }
      .footer-row.copyright-container { height: 116px; }
    }
  }  
</style>

<script>
  import VueCookies from 'vue-cookies'
  import LanguageMenu from "@/components/LanguageMenu.vue";
  import tenants from '@/data/tenants.config'
  const tenant = tenants.current();

  export default {
    props: {
    },
    components: {
      LanguageMenu,
    },
    data: () => ({
      tenant: tenant,
    }),
    mounted() {
    },
    methods: {
      toggleHighContrast() {
        if (this.useHighContrast) {
          this.$vuetify.theme.themes.light = tenant.theme;
          this.$store.commit("setTheme", 'default');
        }
        else {
          this.$vuetify.theme.themes.light = tenant.themeHighContrast;
          this.$store.commit("setTheme", 'highcontrast');
        }
        VueCookies.set('theme', this.$store.state.theme, '30d');
      }
    },
    computed: {
      isEmbedded() {
        return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
      },
      useHighContrast() {
        return this.$store.state.theme === 'highcontrast';
      },
      isEmbeddedInApp() {
        return this.$route.query.view === 'app' || this.$store.state.view == 'app';
      },
    }

  }
</script>