
let config = {
  en: "English",
  fr: "French",
  es: "Spanish",
  de: "German",
  //ar: "اَلْعَرَبِيَّةُ (beta)",
}

export default function getSupportedLocales() {
  let annotatedLocales = []

  for (const code of Object.keys(config)) {
    annotatedLocales.push({
      code,
      name: config[code]
    })
  }

  return annotatedLocales
}