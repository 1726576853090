import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import VuetifyToast from 'vuetify-toast-snackbar'
//import "vuetify/dist/vuetify.min.css";
//import "vuetify/src/styles/main.sass";
//import colors from "vuetify/lib/util/colors";
import rowingIcon from '@/components/icons/rowing.vue'
import yogaIcon from '@/components/icons/yoga.vue'
import ellipticalIcon from '@/components/icons/elliptical.vue'
import paddleboardingIcon from '@/components/icons/paddleboarding.vue'
import pilatesIcon from '@/components/icons/pilates.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  },
});


const vuetifyInstance = new Vuetify({
  icons: {
    iconfont: "fa",
    values: {
      radioOn: 'fa fa-dot-circle',
      radioOff: 'fal fa-circle',
      checkboxOn: 'fa fa-check-square',
      checkboxOff: 'fal fa-square',
      rowing: { // name of our custom icon
        component: rowingIcon, // our custom component
      },
      yoga: { // name of our custom icon
        component: yogaIcon, // our custom component
      },
      elliptical: { // name of our custom icon
        component: ellipticalIcon, // our custom component
      },
      paddleboarding: { // name of our custom icon
        component: paddleboardingIcon, // our custom component
      },
      pilates: { // name of our custom icon
        component: pilatesIcon, // our custom component
      },
    }
  },
  theme: {
    themes: {
      light: tenant.theme,
      Zlight: {
        primary: "#F46524",
        secondary: '#008AFF', // <-- new
        accent: '#005398',
        green: '#00B46E',
        gradient: '#FFC107'
      },
      dark: {
        primary: "#F46524",
        secondary: '#24b2f4',
        accent: '#005398',
        green: '#00B46E',
        gradient: '#FFC107'
      },
      highcontrast: {
        primary: '#ff0000',
        secondary: '#4caf50',
        accent: '#3f51b5',
        error: '#e91e63',
        warning: '#f44336',
        info: '#03a9f4',
        success: '#8bc34a',
        toolbar: '#008752',
        gradient: '#00673e',
        anchor: '#963e16'
      },
      custom: {
          primary: '#ff0000',
          secondary: '#4caf50',
          accent: '#3f51b5',
          error: '#e91e63',
          warning: '#f44336',
          info: '#03a9f4',
          success: '#8bc34a'
      }
    },
    options: {
      customProperties: true // generate css variables
    },
  }
});

Vue.use(VuetifyToast, { $vuetify: vuetifyInstance.framework });

export default vuetifyInstance;