import Vue from "vue";
import VueRouter from "vue-router";
import { getAuth, onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import store from "@/store";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
//const tenant = tenants.current();

Vue.use(VueRouter);

const getTenantHomePostfix = function(){
  if (tenant.id == 'default' || tenant.customHomePostfix == null) {
    return '';
  }
  return `-${tenant.customHomePostfix}`;
};

const getTenantViewPostfix = function(view){
  if (tenant.id == 'default' || tenant.customHomePostfix == null) {
    return '';
  }
  if (tenant.navPostfix == 'cofi' && ['about', 'contact', 'request-demo', 'go', 'features', 'get', 'pricing', 'slack', 'fitness', 'solutions', 'engine'].some(x => x === view)) {
    return '-cofi';
  }
  if (tenant.customRoutesPostfixes && tenant.customRoutesPostfixes.hasOwnProperty(view))  {
    return '-' + tenant.customRoutesPostfixes[view];
  }
  return '';
};

const newCofiSolutionsBeforeEnter = function(to, from, next, newRoute) {
  //console.log('[Router] ]Solutions entered, to:', to, 'from:', from, 'next:', next);
  if (true && tenant.id=== 'cofi') {
    if (to.path == '/solutions/fitness-engine') {
      next('/solutions/integrations/custom-app-platforms');
    }
    else if (to.path == '/solutions/security-privacy') {
      next('/product/benefits/security-data-privacy');
    }
    else if (to.path == '/solutions/walking-challenges') {
      next('/product/challenges/walking-challenges'); // <-----
    }
    else if (to.path == '/solutions/running-challenges') {
      next('/product/challenges/running-challenges');
    }
    else if (to.path == '/solutions/step-challenges') {
      next('/product/challenges/step-challenges');
    }
    else if (to.path == '/solutions/fitness-challenges') {
      next('/product/challenges');
    }
    else if (to.path == '/solutions/challenge-ideas') {
      next('/product/challenges/custom-challenges');
    }
    else {
      next({ name: 'cmsSolutionsHome', params: to.params, query: to.query, path: to.path});
    }
  }
  else {
    return next();
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(`../views/Home${getTenantHomePostfix()}.vue`),
    meta: {
      noLayout: true,
      showChat: 'unchanged',
      //title: 'sodisp - Virtual Challenge Platform',
      title: tenant.title,
      description: tenant.description,
      image: tenant.logoUrl,
      toolbarClass: 'transparent'
    }
  },
  {
    path: "/home-beta",
    name: "homeBeta",
    component: () => import(`../views/Home-beta.vue`),
    meta: {
      noLayout: true,
      title: tenant.title,
      description: tenant.description,
      image: tenant.logoUrl,
      //toolbarClass: 'transparent'
    }
  },
  {
    path: "/about",
    name: "about",
    component: () => import(`../views/About${getTenantViewPostfix('about')}.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: `About Us`,
      description: `Background on who we are and why we started ${tenant.name}`,
      illustration: 'about-white.svg',
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import(`../views/Contact${getTenantViewPostfix('contact')}.vue`),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Contact Us',  
      description: 'Contact us and let us know your thoughts. Use our contact form to send your comments and feedback. We love to hear from you!',
      illustration: 'contact_us-white.svg',
    }
  },
  {
    path: "/request-demo",
    name: "requestDemo",
    component: () => import(`../views/RequestDemo${getTenantViewPostfix('request-demo')}.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: 'Request a Demo',  
      description: 'Request a free 15-30 minute demo where we can show how easy it is to host your own virtual challenge. We love to hear from you!',
      illustration: 'contact_us-white.svg',
    }
  },
  {
    path: "/start-trial",
    name: "startTrial",
    component: () => import(`../views/StartTrial${getTenantViewPostfix('trial')}.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: 'Start a personalized trial',  
      description: 'Start a personalized trial event to see how easy to use our platform is.',
      illustration: 'contact_us-white.svg',
    }
  },
  {
    path: "/trial/:id",
    name: "trial",
    component: () => import(`../views/Trial${getTenantViewPostfix('trial')}Details.vue`),
    meta: {
      requiresAuth: true,
      contentLayout: false,
      showChat: 'show',
      title: 'Share your trial invite',  
      description: 'Start a personalized trial event to see how easy to use our platform is.',
      illustration: 'contact_us-white.svg',
    }
  },
  {
    path: "/go",
    name: "go",
    component: () => import(`../views/Go${getTenantViewPostfix('go')}.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: 'Get Started or Schedule a Meeting',  
      description: 'Schedule a free 15-30 minute meeting or demo where we can show how easy it is to host your own corporate fitness challenge. We love to hear from you!',
      illustration: 'contact_us-white.svg',
    }
  },
  {
    path: "/thankyou",
    name: "thankyou",
    component: () => import("../views/ThankYou.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Thanks!',  
      description: 'Thanks for your interest in sodisp. We will get back to you ASAP.',
      illustration: 'contact_us.svg',
    }
  },
  {
    path: "/checkout/pay",
    name: "checkoutPay",
    component: () => import("../views/checkout/Pay.vue"),
    meta: {
      //requiresAuth: true,
    }
  },
  {
    path: "/checkout/cart",
    name: "checkoutCart",
    component: () => import("../views/checkout/Cart.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/checkout/success",
    name: "checkoutSuccess",
    component: () => import("../views/checkout/Success.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Thanks!',  
      description: 'Thanks! Your payment has been received successfully.',
      illustration: 'contact_us.svg',
    }
  },
  {
    path: "/checkout/cancelled",
    name: "checkoutCancelled",
    component: () => import("../views/checkout/Cancelled.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Payment Cancelled',  
      illustration: 'contact_us.svg',
    }
  },
  {
    path: "/checkout/connect",
    name: "checkoutConnect",
    component: () => import("../views/checkout/Connect.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/Register.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/Login.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/account/signout",
    name: "signout",
    component: () => import("../views/auth/SignOut.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/account/slack",
    name: "slack",
    component: () => import("../views/auth/Slack.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/account/teams",
    name: "teams",
    component: () => import("../views/auth/Teams.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/account/admin-invite",
    name: "teams",
    component: () => import("../views/auth/AdminInvite.vue"),
    meta: {
    }
  },
  {
    path: "/account/passwordrecovery",
    name: "accountPasswordrecovery",
    component: () => import("../views/auth/PasswordRecovery.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/account/changepassword",
    name: "accountChangePassword",
    component: () => import("../views/auth/ChangePassword.vue"),
    meta: {
      noLayout: true,
      requiresAuth: true,
    }
  },
  {
    path: "/account/link",
    name: "accountLink",
    component: () => import("../views/auth/LinkAccount.vue"),
    meta: {
      noLayout: true,
      requiresAuth: true,
    }
  },
  {
    path: "/account/sso/saml",
    name: "accountSso",
    component: () => import("../views/auth/FirebaseSsoSaml.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/account/firebase",
    name: "accountFirebase",
    component: () => import("../views/auth/FirebaseAction.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/admin/training-plan/:id",
    name: "adminTrainingPlan",
    component: () => import("../views/admin/TrainingPlan.vue"),
  },
  {
    path: "/admin/map",
    name: "adminMap",
    component: () => import("../views/admin/Map.vue"),
  },
  {
    path: "/admin/map/:country",
    name: "adminMapByCountry",
    component: () => import("../views/admin/Map.vue"),
  },
  {
    path: "/admin/rawmap",
    name: "adminRawMap",
    component: () => import("../views/admin/Map.development.vue"),
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Privacy Policy',
      description: 'We respect your privacy',
      illustration: 'privacypolicy.svg',
    }
  },
  {
    path: "/cookiepolicy",
    name: "cookiepolicy",
    component: () => import("../views/CookiePolicy.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Cookie Policy',
      description: 'We use cookies to help improve your experience of sodisp.com',
      illustration: 'cookiepolicy.svg',
    }
  },
  {
    path: "/termsofservice",
    name: "termsofservice",
    component: () => import("../views/TermsOfService.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Terms of Service',
      description: '',
      illustration: 'termsofservice.svg',
    }
  },
  {
    path: "/socialdistancing",
    name: "socialdistancing",
    component: () => import("../views/SocialDistancing.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Social Distancing',
      description: '',
      illustration: 'social_distancing.svg',
    }
  },
  {
    path: "/virtual-event",
    name: "virtualEvent",
    component: () => import("../views/StaticContent.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      slug: 'virtual-event',
      title: 'Organize your Virtual Event',
      description: '',
      illustration: 'finish_line.svg',
    }
  },
  {
    path: "/features",
    name: "features",
    component: () => import(`../views/Features${getTenantViewPostfix('features')}.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: 'Create engaging events and challenges',
      description: `Explore the features  ${tenant.name} has to offer to make a success of your event or challenge`,
      illustration: 'features-white.svg',
    }
  },
  {
    path: "/get",
    name: "get",
    component: () => import(`../views/Get${getTenantViewPostfix('get')}.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: `Get the app for iOS or Android`,
      description: `Explore the ${tenant.name} app and how it helps make a success of your event or challenge`,
      illustration: 'app-white.svg',
    }
  },
  {
    path: "/get/apk",
    name: "getApk",
    component: () => import(`../views/GetApk${getTenantViewPostfix('get')}.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: `Get the app for Android`,
      description: `Explore the ${tenant.name} app and how it helps make a success of your event or challenge`,
      illustration: 'app-white.svg',
    }
  },
  {
    path: "/slack",
    redirect: "/integrations/slack",
  },
  {
    path: "/microsoft-teams",
    redirect: "/integrations/microsoft-teams",
  },
  {
    path: "/integrations/slack",
    name: "integrationsSlack",
    component: () => import(`../views/integrations/Slack${getTenantViewPostfix('slack')}.vue`),
    meta: {
      contentLayout: false,
      showChat: 'show',
      ZtoolbarClass: 'transparent',
      title: `Integrate with Slack`,
      description: `Integrate ${tenant.name} with Slack for a streamlined experience of your challenge with your team.`,
      illustration: 'app-white.svg',
    }
  },
  {
    path: "/integrations/microsoft-teams",
    name: "integrationsMicrosoftTeams",
    component: () => import(`../views/integrations/MicrosoftTeams${getTenantViewPostfix('slack')}.vue`),
    meta: {
      contentLayout: false,
      showChat: 'show',
      ZtoolbarClass: 'transparent',
      title: `Integrate with Microsoft Teams`,
      description: `Integrate ${tenant.name} with Microsoft Teams for a streamlined experience of your challenge with your team.`,
      illustration: 'app-white.svg',
    }
  },
  {
    path: "/integrations/zapier",
    name: "integrationsZapier",
    component: () => import(`../views/integrations/Zapier${getTenantViewPostfix('slack')}.vue`),
    meta: {
      contentLayout: false,
      showChat: 'show',
      ZtoolbarClass: 'transparent',
      title: `Integrate with Zapier`,
      description: `Integrate ${tenant.name} with Zapier to synchronize data from Google Sheets, Microsoft Excel, and many more.`,
      illustration: 'app-white.svg',
    }
  },
  {
    path: "/integrations/fitness",
    name: "integrationsFitness",
    component: () => import(`../views/integrations/Fitness${getTenantViewPostfix('fitness')}.vue`),
    meta: {
      contentLayout: false,
      showChat: 'show',
      ZtoolbarClass: 'transparent',
      title: `Integrate with most popular fitness apps and watches`,
      description: `Integrate ${tenant.name} with your favorite fitness app or watch and have it auto-sync all your activities and steps.`,
      illustration: 'app-white.svg',
    }
  },
  {
    path: "/challenges",
    component: () => import(`../views/challenges/_Layout.vue`),
    children: [
      {
        path: "",
        name: "challenges",
        component: () => import(`../views/challenges/Index.vue`),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'Pre-designed challenge templates',
          description: `Explore the various pre-designed challenge templates.`,
          illustration: 'features.svg',
        },
      },
      { path: "european-landmarks", redirect: 'journeys/european-landmarks'},
      { path: "new-zealand-te-araroa-trail", redirect: 'journeys/new-zealand-te-araroa-trail'},
      { path: "seven-wonders-of-the-ancient-world", redirect: 'journeys/seven-wonders-of-the-ancient-world'},
      { path: "solar-system", redirect: 'journeys/solar-system'},
      { path: "us-east-coast", redirect: 'journeys/us-east-coast'},
      { path: "us-national-parks", redirect: 'journeys/us-national-parks'},
      { path: "us-route-66", redirect: 'journeys/us-route-66'},
      { path: "us-west-coast", redirect: 'journeys/us-west-coast'},
      {
        path: "journeys",
        name: "challengeJourneys",
        component: () => import(`../views/challenges/Journeys.vue`),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'Pre-designed journeys',
          description: `Explore the various pre-designed challenge templates.`,
          illustration: 'features.svg',
        },
      },
      {
        path: "journeys/:id",
        name: "challengeJourneyView",
        component: () => import("../views/challenges/ViewJourney.vue"),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'View pre-designed journey details',
          description: `View the details of this pre-designed journey.`,
          illustration: 'features.svg',
        }
      },
      {
        path: "wellbeing-packs",
        name: "challengeWellbeingPacks",
        component: () => import(`../views/challenges/WellbeingPacks.vue`),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'Pre-designed wellbeing packs',
          description: `Explore the various pre-designed challenge templates.`,
          illustration: 'features.svg',
        },
      },
      {
        path: "wellbeing-packs/:id",
        name: "challengeWellbeingPackView",
        component: () => import("../views/challenges/ViewWellbeingPack.vue"),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'View pre-designed wellbeing pack details',
          description: `View the details of this pre-designed journey.`,
          illustration: 'features.svg',
        }
      },
      {
        path: ":id",
        name: "challengeTemplateView",
        component: () => import("../views/challenges/ViewTemplate.vue"),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'View pre-designed challenge details',
          description: `View the details of this pre-designed challenge template.`,
          illustration: 'features.svg',
        }
      }

    ]
  },
  {
    path: "/resources",
    component: () => import(`../views/resources/_Layout.vue`),
    children: [
      /*{
        path: "",
        name: "resources",
        component: () => import(`../views/resources/Index.vue`),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'Resources',
          description: `Use the Sodisp Platform to create engaging endurance sport challenges.`,
          illustration: 'features.svg',
        },
      },*/
      {
        path: "convert-steps",
        name: "resourcesCalculateSteps",
        component: () => import(`../views/resources/ConvertSteps.vue`),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'Step Calculator and Converter',
          description: `Use this online calculator to quickly convert an activity into steps.`,
          illustration: 'features.svg',
        },
      },
    ]
    },
    {
    path: "/solutions",
    beforeEnter: (to, from, next) => newCofiSolutionsBeforeEnter(to, from, next),
    component: () => import(`../views/solutions/_Layout.vue`),
    children: [
      {
        path: "",
        name: "solutions",
        component: () => import(`../views/solutions/Index${getTenantViewPostfix('solutions')}.vue`),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'Solutions',
          description: `Use the Sodisp Platform to create engaging endurance sport challenges.`,
          illustration: 'features.svg',
        },
      },
      {
        path: "corporate-challenges",
        name: "solutionsCorporateChallenges",
        component: () => import("../views/solutions/CorporateChallenges.vue"),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'Engaging corporate challenges',
          description: `Use the Sodisp Challenge Platform to create motivating challenges to keep your employees fit and active.`,
          illustration: 'features.svg',
        }
      },
      {
        path: "year-round-trails",
        name: "solutionsYearRoundTrails",
        component: () => import("../views/solutions/YearRoundTrails.vue"),
        meta: {
          contentLayout: false,
          noLayout: true,
          toolbarClassZ: 'transparent',
          title: 'Engaging year-round trails',
          description: `Take your on-course race and make it relevant 365 days a year! Allow participants to use their favorite app or GPS device to track their result. The sodisp platform will automatically analyze any uploaded activity and match it with your courses. Only tracks which followed the course from start to finish will qualify for the leaderboard.`,
          illustration: 'features.svg',
        }
      },
      {
        path: "white-label",
        name: "solutionsWhiteLabel",
        component: () => import("../views/solutions/WhiteLabel.vue"),
        meta: {
          contentLayout: false,
          title: 'Custom white-label app',
          description: `We can create a white-label app tailor-made for your event. Your branding, your app store account, your event app!`,
          illustration: 'features.svg',
        }
      },
      {
        path: "race-results",
        name: "solutionsRaceResults",
        component: () => import("../views/solutions/RaceResults.vue"),
        meta: {
          contentLayout: false,
          noLayout: true,
          toolbarClassZ: 'transparent',
          title: 'Race Results',
          description: `Publish in-person race results to our fully customizable app and web platform. Leaderboards are customizable and results can be liked and shared. Users can upload their selfies and post comments.`,
          illustration: 'features.svg',
        }
      },
      { 
        path: "events",
        redirect: "/solutions/hybrid-events",
      },
      {
        path: "hybrid-events",
        name: "solutionsHybridEvents",
        component: () => import("../views/solutions/HybridEvents.vue"),
        meta: {
          contentLayout: false,
          noLayout: true,
          toolbarClassZ: 'transparent',
          title: 'Engaging hybrid and virtual events',
          description: `Whether you go virtual or hybrid, Sodisp offers challenge solutions to increase sponsorship value and participant engagement of your event!`,
          illustration: 'features.svg',
        }
      },
      {
        path: "fitness-engine",
        name: "solutionsEngine",
        component: () => import(`../views/solutions/Engine${getTenantViewPostfix('engine')}.vue`),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'Customized fitness apps and platform',
          description: `Embed and connect with our fitness engine as a service to instantly add Strava, Fitbit, Garmin, Polar, and COROS integrations to any product! `,
          illustration: 'features.svg',
        }
      },
      {
        path: "security-privacy",
        name: "solutionsSecurityPrivacy",
        component: () => import(`../views/solutions/SecurityPrivacy${getTenantViewPostfix('zzzzzzzzz')}.vue`),
        meta: {
          contentLayout: false,
          toolbarClassZ: 'transparent',
          title: 'Secure and privacy-first',
          description: `We take the security and privacy of your data very serious. Learn more about the various options available to ensure the highest level of security for the data of your organization and your employees.`,
          illustration: 'features.svg',
        }
      },
      {
        path: "relay-races",
        name: "solutionsRelayRaces",
        component: () => import("../views/solutions/RelayRaces.vue"),
        meta: {
          contentLayout: true,
          toolbarClass: 'transparent',
          title: 'Engaging relay races',
          description: `Explore the features  ${tenant.name} has to offer to make a success of your virtual event or challenge`,
          illustration: 'features.svg',
        }
      },
      {
        path: "walking-challenges",
        name: "solutionsWalkingChallenges",
        component: () => import("../views/solutions/cofi/WalkingChallenges.vue"),
        meta: {
          noLayout: true,
          toolbarClass: 'transparent',
          showChat: 'show',
          title: 'Engaging walking challenges',
          description: `Create engaging walking challenges to keep your employees fit and active.`,
          illustration: 'features.svg',
        }
      },
      {
        path: "running-challenges",
        name: "solutionsRunningChallenges",
        component: () => import("../views/solutions/cofi/RunningChallenges.vue"),
        meta: {
          noLayout: true,
          toolbarClass: 'transparent',
          showChat: 'show',
          title: 'Engaging running challenges',
          description: `Create engaging running challenges to keep your employees fit and active.`,
          illustration: 'features.svg',
        }
      },
      {
        path: "step-challenges",
        name: "solutionsStepChallenges",
        component: () => import("../views/solutions/cofi/StepChallenges.vue"),
        meta: {
          noLayout: true,
          toolbarClass: 'transparent',
          showChat: 'show',
          title: 'Engaging step challenges',
          description: `Create engaging step challenges to keep your employees fit and active.`,
          illustration: 'features.svg',
        }
      },
      {
        path: "fitness-challenges",
        name: "solutionsFitnessChallenges",
        component: () => import("../views/solutions/cofi/FitnessChallenges.vue"),
        meta: {
          noLayout: true,
          toolbarClass: 'transparent',
          showChat: 'show',
          title: 'Engaging fitness and wellness challenges',
          description: `Create engaging fitness and wellness challenges to keep your employees fit and active.`,
          illustration: 'features.svg',
        }
      },
      {
        path: "challenge-ideas",
        name: "solutionsChallengeIdeas",
        component: () => import("../views/solutions/cofi/ChallengeIdeas.vue"),
        meta: {
          noLayout: true,
          toolbarClass: 'transparent',
          showChat: 'show',
          title: 'Fitness Challenge Ideas',
          description: `Explore the engaging corporate fitness challenges you can create using corporatefitness.app.`,
          illustration: 'features.svg',
        }
      },
    ]
  },
  {
    path: "/solutions/:params*",
    name: "cmsSolutionsHome",
    component: () => import("../views/CmsPage.vue"),
    meta: {
      contentLayout: false,
      noLayout: true,
      layoutClass: 'cms',
      toolbarClass: 'transparent',
    }
  },  
  {
    path: "/pricing",
    name: "pricing",
    component: () => import(`../views/Pricing${getTenantViewPostfix('pricing')}.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: 'Create fitness challenges teams love',
      description: 'We like to keep things simple. This is why we have fair and transparent pricing for hosting virtual events and challenges.',
      illustration: 'finish_line-white.svg',
    }
  },
  {
    path: "/pricing/support-plans",
    name: "pricingSupportPlans",
    component: () => import(`../views/PricingSupportPlans.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: 'Get the support plan that works best for you',
      description: 'We offer different support plans ranging from self-service to fully managed support.',
      illustration: 'finish_line-white.svg',
    }
  },
  {
    path: "/pricing2024",
    name: "pricing2024",
    component: () => import(`../views/Pricing2024.vue`),
    meta: {
      contentLayout: true,
      showChat: 'show',
      toolbarClass: 'transparent',
      title: 'Create fitness challenges teams love',
      description: 'We like to keep things simple. This is why we have fair and transparent pricing for hosting virtual events and challenges on the sodisp platform.',
      illustration: 'finish_line-white.svg',
    }
  },
  {
    path: "/product/:params*",
    name: "cmsProductHome",
    component: () => import("../views/CmsPage.vue"),
    meta: {
      contentLayout: false,
      noLayout: true,
      layoutClass: 'cms',
      toolbarClass: 'transparent',
    }
  },  
  {
    path: "/productZZ/*",
    name: "cmsProduct",
    component: () => import("../views/CmsPage.vue"),
    meta: {
      contentLayout: false,
      noLayout: true,
      layoutClass: 'cms',
      toolbarClass: 'transparent',
    }
  },  
  {
    path: "/articles/:id",
    name: "article",
    component: () => import("../views/Article.vue"),
    meta: {
    }
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/Blog.vue"),
    meta: {
    }
  },
  {
    path: "/blog/:id",
    name: "blogPost",
    component: () => import("../views/BlogPost.vue"),
    meta: {
    }
  },
  {
    path: "/resources/case-studies",
    name: "resourcesCaseStudies",
    component: () => import("../views/CaseStudies.vue"),
    meta: {
    }
  },
  /* -------------- Redirects ------------------ */
  {
    path: "/support",
    redirect: to => ({
      name: "contact",
      query: { mode: 'support' },
    }),
  },
  {
    path: "/r/go/review",
    beforeEnter(to, from, next) {
      window.location.replace("https://reviews.capterra.com/new/357821/115888ee-151b-4e48-9920-774dd1ec9539?lang=en");
    }
  },
  {
    path: "/r/go/book-demo",
    beforeEnter(to, from, next) {
      window.location.replace("https://outlook.office365.com/owa/calendar/CorporateFitnessappDemoCall@noctilucent.eu/bookings/");
    }
  },
  {
    // Deeplink from Branch join link to web
    path: "/r/dl/join",
    redirect: to => ({
      name: "register",
      query: { event: to.query.event, verificationCode: to.query.code },
    }),
  },
  {
    // Deeplink from Branch view link to web
    path: "/r/dl/event",
    redirect: to => ({
      name: "event",
      params: { id: to.query.event },
    }),
  },
  {
    path: "/r/a/:appBundle/o/:id/join",
    redirect: to => ({
      name: "register",
      query: { org: to.params.id, verificationCode: to.query.code, appBundle: to.params.appBundle },
    }),
  },
  /*
  if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
             window.location.href = 
           'http://play.google.com/store/apps/details?id=PACKAGEURL&referrer=SOMETHING';
     }
         if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
             window.location.href = 
       'http://itunes.apple.com/lb/app/PACKAGEURL';
     }
  */
  {
    path: "/r/a/:appBundle/o/:id",
    redirect: to => ({
      name: "organization",
      params: { id: to.params.id },
      query: { appBundle: to.params.appBundle },
    }),
  },
  {
    path: "/r/a/:appBundle/p/friends",
    redirect: to => ({
      name: "profileFriends",
      query: { appBundle: to.params.appBundle },
    }),
  },
  {
    path: "/r/a/:appBundle/p/friends/invite/:profileId",
    redirect: to => ({
      name: "profileFriendInvite",
      params: { profileId: to.params.profileId },
      query: { appBundle: to.params.appBundle },
    }),
  },
  {
    path: "/r/a/:appBundle/:id/join",
    redirect: to => ({
      name: "register",
      query: { event: to.params.id, verificationCode: to.query.code, appBundle: to.params.appBundle },
    }),
  },
  {
    path: "/r/a/:appBundle/:id",
    redirect: to => ({
      name: "event",
      params: { id: to.params.id },
      query: { appBundle: to.params.appBundle },
    }),
  },
  /* -------------- Events ------------------ */
  {
    path: "/events/:id/welcome",
    name: "eventWelcome",
    component: () => import("../views/EventWelcome.vue"),
    meta: {
      requiresAuth: true,
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/invite",
    name: "eventInvite",
    component: () => import("../views/EventInvite.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/getting-started",
    name: "eventGettingStarted",
    component: () => import("../views/EventGettingStarted.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/getting-started-china",
    name: "eventGettingStartedChina",
    component: () => import("../views/EventGettingStartedChina.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/instructions",
    name: "eventInstructions",
    component: () => import("../views/EventInstructions.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/feed",
    name: "eventFeed",
    component: () => import("../views/EventFeed.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/gallery",
    name: "eventGallery",
    component: () => import("../views/EventGallery.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/join",
    name: "eventJoin",
    component: () => import("../views/EventJoin.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/login",
    redirect: to => ({
      name: "login",
      query: { 
        event: to.params.id, 
        verificationCode: to.query.verificationCode, 
        view: to.query.view, 
        mode: 'connect-only', 
      },
    }),
  },
  {
    path: "/events/:id/quickentry",
    name: "eventQuickEntry",
    component: () => import("../views/EventQuickEntry.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/reg",
    component: () => import("../views/reg/_RegLayout.vue"),
    meta: {
      enableCustomTrackers: true,
      requiresAuthInApp: true,
    },
    children: [
      {
        path: "",
        name: "eventReg",
        component: () => import("../views/reg/RegForm.vue"),
        meta: {
          enableCustomTrackers: true,
        },
      },      
      {
        path: ":code/confirm",
        name: "eventRegConfirm",
        component: () => import("../views/reg/RegConfirm.vue"),
        meta: {
          enableCustomTrackers: true,
        },
      },      
      {
        path: ":code/completed",
        name: "eventRegCompleted",
        component: () => import("../views/reg/RegCompleted.vue"),
        meta: {
          enableCustomTrackers: true,
        },
      },      
    ]
  },
  {
    path: "/events/:id/:raceId/results",
    name: "raceResults",
    component: () => import("../views/RaceResults.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/invite",
    name: "raceInvite",
    component: () => import("../views/EventInvite.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/results/:resultId",
    name: "raceResult",
    component: () => import("../views/RaceResult.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/results/:resultId/claim",
    name: "raceClaimResult",
    component: () => import("../views/RaceClaimResult.vue"),
    meta: {
      requiresAuth: true,
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/results/:resultId/streetview",
    name: "raceStreetviewResult",
    component: () => import("../views/RaceResultStreetView.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/badges/:badgeId",
    name: "raceBadge",
    component: () => import("../views/RaceBadge.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/result",
    name: "raceResultByCode",
    component: () => import("../views/RaceResult.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId",
    name: "race",
    component: () => import("../views/Race.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id",
    name: "event",
    component: () => import("../views/Event.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events",
    name: "events",
    component: () => import("../views/Events.vue"),
    meta: {
      title: `Virtual Challenges and Events on ${tenant.name}`,
      description: 'Explore (or create your own) virtual event or challenges. Join using Strava or Garmin.',
      image: 'https://sodisp.imgix.net/running-bridge.jpg?w=1080&h=500&fit=crop',
    }
  },

  /* -------------- RANKINGS ------------------ */
  {
    path: "/rankings",
    name: "map",
    component: () => import("../views/rankings/Index.vue"),
    meta: {
      title: 'Global rankings',
      description: 'Explore global rankings per activity type.',
      image: null,
    }
  },

  /* -------------- Tiles ------------------ */
  {
    path: "/tiles/map/:country?",
    name: "map",
    component: () => import("../views/tiles/Map.vue"),
    meta: {
      title: 'Tile map on sodisp',
      description: 'Explore local running, cycling and walking leaderboards worldwide. We have divided the world into hexagons (which we call tiles). Each activity you complete is automatically assigned to the correct tile(s) and participates in the local leaderboard.',
      image: null,
    }
  },
  {
    path: "/tiles/countries",
    name: "countries",
    component: () => import("../views/tiles/Countries.vue"),
    meta: {
      title: 'Lookup all tiles by country',
      description: 'Explore local running, cycling and walking leaderboards worldwide. We have divided the world into hexagons (which we call tiles). Each activity you complete is automatically assigned to the correct tile(s) and participates in the local leaderboard.',
      image: null,
    }
  },
  {
    path: "/tiles/:id/:type?",
    name: "tile",
    component: () => import("../views/tiles/Tile.vue"),
  },

  /* -------------- Groups, Orgs and Series ------------------ */
  {
    path: "/by/:id",
    name: "organizerView",
    component: () => import("../views/GroupView.vue"),
    meta: {
      groupType: 'ORGANIZATION',
    },
  },
  {
    path: "/series/:id",
    name: "seriesView",
    component: () => import("../views/GroupView.vue"),
    meta: {
      groupType: 'SERIES',
    },
  },


  /* -------------- Integration Apps ------------------ */
  {
    path: "/organizations/:id",
    name: "organization",
    component: () => import("../views/organizations/View.vue"),
    meta: {
    },
  },
  {
    path: "/organizations/:id/join",
    name: "organizationJoin",
    component: () => import("../views/organizations/Join.vue"),
    meta: {
    },
  },
  {
    path: "/organizations/:id/welcome",
    name: "organizationWelcome",
    component: () => import("../views/organizations/Welcome.vue"),
    meta: {
    },
  },
  {
    path: "/organizations/:id/challenges",
    name: "organizationChallenges",
    component: () => import("../views/organizations/MyChallenges.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/organizations/:id/challenges/create",
    name: "organizationChallengesCreate",
    component: () => import("../views/organizations/CreateChallenge.vue"),
    meta: {
      requiresAuth: true,
    },  
  },
  {
    path: "/organizations/:id/challenges/:eventId",
    name: "organizationChallengesView",
    component: () => import("../views/organizations/ViewChallenge.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/organizations/:id/challenges/:eventId/config",
    name: "organizationChallengesConfig",
    component: () => import("../views/organizations/CreateChallenge.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/organizations/:id/challenges/:eventId/feed",
    name: "organizationChallengesFeed",
    component: () => import("../views/organizations/ChallengeFeed.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  /* -------------- Integration Apps ------------------ */
  {
    path: "/apps/teams",
    component: () => import("../views/apps/teams/_TeamsApp.vue"),
    meta: {
      noLayout: true,
    },
    children: [
      {
        path: "",
        name: "appsTeamsTab",
        component: () => import("../views/apps/teams/TeamsTabConfig.vue"),
      },
      {
        path: "content",
        name: "appsTeamsTabContent",
        component: () => import("../views/apps/teams/TeamsTabContent.vue"),
      },
      {
        path: "result",
        name: "appsTeamsTabResult",
        component: () => import("../views/apps/teams/TeamsTabResult.vue"),
      },
      {
        path: "auth",
        component: () => import("../views/apps/teams/_TeamsApp.vue"),
        children: [
          {
            path: "start",
            name: "appsTeamsTabAuthStart",
            component: () => import("../views/apps/teams/auth/TeamsAuthStart.vue"),
          },
          {
            path: "end",
            name: "appsTeamsTabAuthEnd",
            component: () => import("../views/apps/teams/auth/TeamsAuthEnd.vue"),
          },
          {
            path: "start-microsoft",
            name: "appsTeamsTabAuthStartSilent",
            component: () => import("../views/apps/teams/auth/TeamsAuthStartSilent.vue"),
          },
          {
            path: "end-microsoft",
            name: "appsTeamsTabAuthEndSilent",
            component: () => import("../views/apps/teams/auth/TeamsAuthEndSilent.vue"),
          },
        ],
      },
    
    ]
  },
  {
    path: "/apps/teams-v2",
    component: () => import("../views/apps/teams-v2/_TeamsApp.vue"),
    meta: {
      noLayout: true,
    },
    children: [
      {
        path: "",
        name: "appsTeamsTab2",
        component: () => import("../views/apps/teams-v2/TeamsTabConfig.vue"),
      },
      {
        path: "content",
        name: "appsTeamsTabContent2",
        component: () => import("../views/apps/teams-v2/TeamsTabContent.vue"),
      },
      {
        path: "result",
        name: "appsTeamsTabResult2",
        component: () => import("../views/apps/teams-v2/TeamsTabResult.vue"),
      },
      {
        path: "auth",
        component: () => import("../views/apps/teams-v2/_TeamsApp.vue"),
        children: [
          {
            path: "start",
            name: "appsTeamsTabAuthStart2",
            component: () => import("../views/apps/teams-v2/auth/TeamsAuthStart.vue"),
          },
          {
            path: "end",
            name: "appsTeamsTabAuthEnd2",
            component: () => import("../views/apps/teams-v2/auth/TeamsAuthEnd.vue"),
          },
          {
            path: "start-microsoft",
            name: "appsTeamsTabAuthStartSilent2",
            component: () => import("../views/apps/teams-v2/auth/TeamsAuthStartSilent.vue"),
          },
          {
            path: "end-microsoft",
            name: "appsTeamsTabAuthEndSilent2",
            component: () => import("../views/apps/teams-v2/auth/TeamsAuthEndSilent.vue"),
          },
        ],
      },
    
    ]
  },
  /* -------------- Profile ------------------ */

  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/edit",
    name: "profileEdit",
    component: () => import("../views/profile/ProfileEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/removal",
    name: "profileRemoval",
    component: () => import("../views/profile/ProfileRemoval.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/takeout",
    name: "profileTakeout",
    component: () => import("../views/profile/ProfileTakeout.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/keep",
    name: "profileKeep",
    component: () => import("../views/profile/ProfileKeep.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/onboarding",
    name: "profileOnboarding",
    component: () => import("../views/profile/ProfileOnboarding.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/onboarding/completed",
    name: "profileOnboardingCompleted",
    component: () => import("../views/profile/ProfileOnboardingCompleted.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/mfa",
    name: "profileMfa",
    component: () => import("../views/profile/ProfileMFA.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/connections",
    name: "profileConnections",
    component: () => import("../views/profile/Connections.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/friends",
    name: "profileFriends",
    component: () => import("../views/profile/friends/Friends.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/friends/invite/:profileId",
    name: "profileFriendInvite",
    component: () => import("../views/profile/friends/FriendInvite.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/friends/challenges/:eventId",
    name: "profileFriendsChallengeView",
    component: () => import("../views/profile/friends/ChallengeView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/friends/challenges/:eventId/config",
    name: "profileFriendsChallengeConfig",
    component: () => import("../views/profile/friends/ChallengeConfig.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/feed",
    name: "profileFeed",
    component: () => import("../views/profile/Feed.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/upload",
    name: "profileUpload",
    component: () => import("../views/profile/UploadPhoto.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/upload",
    name: "activityUpload",
    component: () => import("../views/profile/ActivityUpload.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/entry",
    name: "activityEntry",
    component: () => import("../views/profile/ActivityEntry-v2.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    // legacy, can be removed after July '24
    path: "/profile/activities/entry-v1",
    name: "activityEntry",
    component: () => import("../views/profile/ActivityEntry.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    // legacy, can be removed after July '24
    path: "/profile/activities/entry-v2",
    name: "activityEntry",
    component: () => import("../views/profile/ActivityEntry-v2.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/claim/:eventId/:raceId",
    name: "activityClaim",
    component: () => import("../views/profile/ActivityClaim.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/claim-confirm/:eventId/:raceId",
    name: "activityClaimConfirm",
    component: () => import("../views/profile/ActivityClaimConfirm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/:id",
    name: "activity",
    component: () => import("../views/profile/Activity.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/edit/:id",
    name: "activityEdit",
    component: () => import("../views/profile/ActivityEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/delete/:id",
    name: "activityDelete",
    component: () => import("../views/profile/ActivityDelete.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities",
    name: "activities",
    component: () => import("../views/profile/Activities.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/plans/:eventId/:raceId",
    name: "profilePlan",  
    component: () => import("../views/profile/plans/TrainingPlan.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/groups",
    name: "profileGroups",
    component: () => import("../views/profile/groups/MyGroups.vue"),
  },
  {
    path: "/profile/groups/create",
    name: "profileGroupsCreate",
    component: () => import("../views/profile/groups/Create.vue"),
  },
  {
    path: "/profile/groups/edit/:id",
    name: "profileGroupsEdit",
    component: () => import("../views/profile/groups/Edit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/groups/view/:id",
    name: "profileGroupsView",
    component: () => import("../views/profile/groups/View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/groups/feed/:id",
    name: "profileGroupsFeed",
    component: () => import("../views/profile/groups/Feed.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/groups/tenant/join",
    name: "profileJoinTenantTeam",
    component: () => import("../views/profile/groups/JoinTenantTeam.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/groups/event/:id/:eventId",
    name: "profileGroupsEvent",
    component: () => import("../views/profile/groups/Event.vue"),
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: "/profile/groups/event/:id/:eventId/reports",
    name: "profileGroupsEventReports",
    component: () => import("../views/profile/groups/EventReports.vue"),
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: "/eventmanager/view/:id",
    redirect: "/eventmanager/:id",
  },
  {
    path: "/eventmanager",
    component: () => import(`../views/eventmanager/_Layout.vue`),
    meta: {
      noLayout: true,
    },
    children: [
      {
        path: "",
        name: "eventmanager",
        component: () => import(`../views/eventmanager/Index.vue`),
      },
      {
        path: "archived",
        name: "eventmanagerArchived",
        component: () => import(`../views/eventmanager/Archived.vue`),
      },
      {
        path: "ads",
        name: "eventmanagerAds",
        component: () => import(`../views/eventmanager/Ads.vue`),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "recent",
        name: "eventmanagerRecent",
        component: () => import("../views/eventmanager/Recent.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "upcoming",
        name: "eventmanagerUpcoming",
        component: () => import("../views/eventmanager/Upcoming.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "renewals",
        name: "eventmanagerRenewals",
        component: () => import("../views/eventmanager/Renewals.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "create",
        name: "eventmanagerCreate",
        component: () => import("../views/eventmanager/Create.vue"),
        meta: {
          showChat: 'show',
        }
      },
      {
        path: "start",
        name: "eventmanagerStart",
        component: () => import("../views/eventmanager/StartFromTemplate.vue"),
        meta: {
          showChat: 'show',
        }
      },
      {
        path: "link-slack",
        name: "eventmanagerLinkSlack",
        component: () => import("../views/eventmanager/LinkSlack.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "link-teams",
        name: "eventmanagerLinkTeams",
        component: () => import("../views/eventmanager/LinkTeams.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "simulator",
        name: "eventmanagerSimulator",
        component: () => import("../views/eventmanager/Simulator.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "organizations",
        component: () => import("../views/eventmanager/organizations/_Layout.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "orgmanager",
            component: () => import("../views/eventmanager/organizations/Index.vue"),
          },
          {
            path: "create",
            name: "orgmanagerCreate",
            component: () => import("../views/eventmanager/organizations/Create.vue"),
          },
          {
            path: "recent",
            name: "orgmanagerRecent",
            component: () => import("../views/eventmanager/organizations/Recent.vue"),
            meta: {
            },
          },          {
            path: ":id",
            component: () => import(`../views/eventmanager/organizations/_OrgLayout.vue`),
            children: [
              {
                path: "",
                name: "orgmanagerView",
                component: () => import("../views/eventmanager/organizations/View.vue"),
              },
              {
                path: "edit",
                name: "orgmanagerEdit",
                component: () => import("../views/eventmanager/organizations/Edit.vue"),
              },
              {
                path: "subscription",
                name: "orgmanagerSubscription",
                component: () => import("../views/eventmanager/organizations/Subscription.vue"),
              },
              {
                path: "subscription/cancel",
                name: "orgmanagerSubscriptionCancel",
                component: () => import("../views/eventmanager/organizations/SubscriptionCancel.vue"),
              },
              {
                path: "events",
                name: "orgmanagerEvents",
                component: () => import("../views/eventmanager/organizations/Events.vue"),
              },
              {
                path: "users",
                name: "orgmanagerUsers",
                component: () => import("../views/eventmanager/organizations/Users.vue"),
              },
              {
                path: "disconnect/:profileId",
                name: "orgmanagerDisconnect",
                component: () => import("../views/eventmanager/organizations/Disconnect.vue"),
              },
              {
                path: "integrations",
                name: "orgmanagerIntegrations",
                component: () => import("../views/eventmanager/organizations/Integrations.vue"),
              },
              {
                path: "integrations/sso",
                name: "orgmanagerIntegrationsSso",
                component: () => import("../views/eventmanager/organizations/IntegrationSso.vue"),
              },
                  {
                path: "integrations/app",
                name: "orgmanagerIntegrationApp",
                component: () => import("../views/eventmanager/organizations/IntegrationApp.vue"),
              },
              {
                path: "oauthclients/:provider",
                name: "orgmanagerOAuthClient",
                component: () => import("../views/eventmanager/organizations/OAuthClient.vue"),
              },
              {
                path: "permissions",
                name: "orgmanagerPermissions",
                component: () => import("../views/eventmanager/organizations/Permissions.vue"),
              },
            ]
          },    
        ],
      },
      {
        path: ":id",
        component: () => import(`../views/eventmanager/_EventLayout.vue`),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "eventmanagerView",
            component: () => import("../views/eventmanager/View.vue"),
          },
          {
            path: "edit",
            name: "eventmanagerEdit",
            component: () => import("../views/eventmanager/Edit.vue"),
          },
          {
            path: "wizard",
            name: "eventmanagerWizard",
            component: () => import("../views/eventmanager/SetupWizard.vue"),
            meta: {
              sidebar: 'HIDDEN',
            },
          },
          {
            path: "config/template",
            name: "eventmanagerConfigTemplate",
            component: () => import("../views/eventmanager/ConfigTemplate.vue"),
            meta: {
              sidebar: 'HIDDEN',
            },
          },
          {
            path: "lock",
            name: "eventmanagerLock",
            component: () => import("../views/eventmanager/Lock.vue"),
          },
          {
            path: "archive",
            name: "eventmanagerArchive",
            component: () => import("../views/eventmanager/Archive.vue"),
          },
          {
            path: "clone",
            name: "eventmanagerClone",
            component: () => import("../views/eventmanager/Clone.vue"),
          },
          {
            path: "link-org",
            name: "eventmanagerLinkOrg",
            component: () => import("../views/eventmanager/LinkOrg.vue"),
          },
          {
            path: "registrations",
            name: "eventmanagerRegistrations",
            component: () => import("../views/eventmanager/Registrations.vue"),
          },
          {
            path: "participants",
            name: "eventmanagerParticipants",
            component: () => import("../views/eventmanager/Participants.vue"),
          },
          {
            path: "feed",
            name: "eventmanagerFeed",
            component: () => import("../views/eventmanager/Feed.vue"),
          },
          {
            path: "license",
            name: "eventmanagerLicense",
            component: () => import("../views/eventmanager/License.vue"),
          },
          {
            path: "allowlist",
            name: "eventmanagerAllowlist",
            component: () => import("../views/eventmanager/Allowlist.vue"),
          },
          {
            path: "blocklist",
            name: "eventmanagerBlocklist",
            component: () => import("../views/eventmanager/Blocklist.vue"), 
          },
          {
            path: "conversions",
            name: "eventmanagerActivityConversions",
            component: () => import("../views/eventmanager/ActivityConversions.vue"),
          },
          {
            path: "gallery",
            name: "eventmanagerGallery",
            component: () => import("../views/eventmanager/Gallery.vue"),
          },
          {
            path: "videos",
            name: "eventmanagerVideos",
            component: () => import("../views/eventmanager/Videos.vue"),
          },
          {
            path: "reports",
            name: "eventmanagerReports",
            component: () => import("../views/eventmanager/Reports.vue"),
          },
          {
            path: "promotion",
            name: "eventmanagerPromotion",
            component: () => import("../views/eventmanager/Promotion.vue"),
          },
          {
            path: "translations",
            name: "eventmanagerTranslations",
            component: () => import("../views/eventmanager/Translations.vue"),
          },
          {
            path: "emails",
            name: "eventmanagerEmails",
            component: () => import("../views/eventmanager/Emails.vue"),
          },
          {
            path: "certificates",
            name: "eventmanagerCertificates",
            component: () => import("../views/eventmanager/Certificates.vue"),
          },
          {
            path: "teams",
            name: "eventmanagerTeams",
            component: () => import("../views/eventmanager/Teams.vue"),
          },
          {
            path: "teamassignment",
            name: "eventmanagerTeamAssignment",
            component: () => import("../views/eventmanager/TeamAssignment.vue"),
          },
          {
            path: "teams/invites",
            name: "eventmanagerTeamInvites",
            component: () => import("../views/eventmanager/TeamInvites.vue"),
          },
          {
            path: "teams/:groupId/permissions",
            name: "eventmanagerTeamPermissions",
            component: () => import("../views/eventmanager/TeamPermissions.vue"),
          },
          {
            path: "participants/:profileId",
            name: "eventmanagerParticipant",
            component: () => import("../views/eventmanager/Participant.vue"),
          },
          {
            path: "permissions",
            name: "eventmanagerPermissions",
            component: () => import("../views/eventmanager/Permissions.vue"),
          },
          {
            path: "config/registration",
            name: "eventmanagerConfigRegistration",
            component: () => import("../views/eventmanager/ConfigRegistration.vue"),
          },
          {
            path: "config/design",
            name: "eventmanagerConfigDesign",
            component: () => import("../views/eventmanager/ConfigDesign.vue"),
          },
          {
            path: "config/activity-types",
            name: "eventmanagerConfigActivityTypes",
            component: () => import("../views/eventmanager/ConfigActivityTypes.vue"),
          },
          {
            path: "config/onboarding",
            name: "eventmanagerConfigOnboarding",
            component: () => import("../views/eventmanager/ConfigOnboarding.vue"),
          },
          {
            path: "tasks",
            name: "eventmanagerTasks",
            component: () => import("../views/eventmanager/Tasks.vue"),
          },
          {
            path: "tasks/:taskId",
            name: "eventmanagerTask",
            component: () => import("../views/eventmanager/Task.vue"),
          },
          {
            path: "oauthclients/:provider",
            name: "eventmanagerOAuthClient",
            component: () => import("../views/eventmanager/OAuthClient.vue"),
          },
          {
            path: "integrations/app",
            name: "eventmanagerIntegrationsApp",
            component: () => import("../views/eventmanager/IntegrationApp.vue"),
          },
          {
            path: "integrations/sso",
            name: "eventmanagerIntegrationsSso",
            component: () => import("../views/eventmanager/IntegrationSso.vue"),
          },
          {
            path: "integrations/eventscom",
            name: "eventmanagerIntegrationsEventsCom",
            component: () => import("../views/eventmanager/IntegrationEventsCom.vue"),
          },
          {
            path: "integrations/classy",
            name: "eventmanagerIntegrationsClassy",
            component: () => import("../views/eventmanager/IntegrationClassy.vue"),
          },
          {
            path: "integrations/miro",
            name: "eventmanagerIntegrationsMiro",
            component: () => import("../views/eventmanager/IntegrationMiro.vue"),
          },
          {
            path: "integrations/slack",
            name: "eventmanagerIntegrationsSlack",
            component: () => import("../views/eventmanager/IntegrationSlack.vue"),
          },
          {
            path: "integrations/teams",
            name: "eventmanagerIntegrationsTeams",
            component: () => import("../views/eventmanager/IntegrationTeams.vue"),
          },
          {
            path: "integrations",
            name: "eventmanagerIntegrations",
            component: () => import("../views/eventmanager/Integrations.vue"),
          },
          {
            path: "link-next",
            name: "eventmanagerLinkNext",
            component: () => import("../views/eventmanager/LinkNext.vue"),
          },
          {
            path: "leaderboards",
            name: "eventmanagerRaces",
            component: () => import("../views/eventmanager/Races.vue"),
          },
          {
            path: ":raceId",
            component: () => import(`../views/eventmanager/_RaceLayout.vue`),
            children: [
              {
                path: "",
                name: "eventmanagerRace",
                component: () => import("../views/eventmanager/Race.vue"),
              },
              {
                path: "results",
                name: "eventmanagerRaceResults",
                component: () => import("../views/eventmanager/RaceResults.vue"),
              },
              {
                path: "columns",
                name: "eventmanagerRaceResultColumns",
                component: () => import("../views/eventmanager/ResultColumns.vue"),
              },
              {
                path: "timeslots",
                name: "eventmanagerRaceTimeSlots",
                component: () => import("../views/eventmanager/RaceTimeSlots.vue"),
              },
              {
                path: "tiers",
                name: "eventmanagerRaceTiers",
                component: () => import("../views/eventmanager/RaceTiers.vue"),
              },
              {
                path: "relayslots",
                name: "eventmanagerRaceRelaySlots",
                component: () => import("../views/eventmanager/RelaySlots.vue"),
              },
              {
                path: "badges",
                name: "eventmanagerRaceBadges",
                component: () => import("../views/eventmanager/Badges.vue"),
              },              
              {
                path: "template",
                name: "eventmanagerRaceTemplate",
                component: () => import("../views/eventmanager/RaceTemplate.vue"),
              },              
              {
                path: "splits",
                name: "eventmanagerRaceSplits",
                component: () => import("../views/eventmanager/Splits.vue"),
              },              
              {
                path: "geofence",
                name: "eventmanagerRaceGeofence",
                component: () => import("../views/eventmanager/Geofence.vue"),
              },
              {
                path: "course",
                name: "eventmanagerRaceCourse",
                component: () => import("../views/eventmanager/RaceCourse.vue"),
              },
              {
                path: "completion-badge",
                name: "eventmanagerRaceCompletionBadge",
                component: () => import("../views/eventmanager/RaceCompletionBadge.vue"),
              },
              {
                path: "clone",
                name: "eventmanagerRaceClone",
                component: () => import("../views/eventmanager/RaceClone.vue"),
              },
            ]
          },    
        ],
      },
    ]
  },
  {
    path: "/admin/home",
    name: "adminHome",
    component: () => import("../views/admin/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/profile/:profileId?",
    name: "adminProfile",
    component: () => import("../views/admin/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/events",
    component: () => import("../views/admin/_AdminBaseLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "adminEvents",
        component: () => import("../views/admin/Events.vue"),
      },
      {
        path: ":id",
        component: () => import("../views/admin/_AdminEventLayout.vue"),
        children: [
          {
            path: "",
            name: "adminEventDetails",
            component: () => import("../views/admin/EventDetails.vue"),
          },
        ],
      },
    ]
  },
  {
    path: "/admin/tenant",
    component: () => import("../views/admin/_AdminBaseLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "adminTenant",
      },
      {
        path: "reports",
        name: "adminTenantReports",
        component: () => import("../views/admin/tenant/Reports.vue"),
      },
    ]
  },
  {
    path: "/admin/orgs",
    component: () => import("../views/admin/_AdminBaseLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      // {
      //   path: "",
      //   name: "adminOrgs",
      //   component: () => import("../views/admin/Orgs.vue"),
      // },
      {
        path: ":id",
        component: () => import("../views/admin/_AdminOrgLayout.vue"),
        children: [
          {
            path: "",
            name: "adminOrgDetails",
            component: () => import("../views/admin/OrgDetails.vue"),
          },
        ],
      },
    ]
  },
  {
    path: "/admin/custommap",
    name: "adminCustomMap",
    component: () => import("../views/admin/CustomMap.vue"),
    meta: {
      noLayout: true,
      requiresAuth: true,
    },
  },
  {
    path: "/admin/design",
    name: "adminDesign",
    component: () => import("../views/admin/Design.vue"),
    meta: {
      noLayout: false,
      requiresAuth: true,
    },
  },
  {
    path: "/admin/streetview",
    name: "adminStreetview",
    component: () => import("../views/admin/Streetview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

if (process.env.NODE_ENV === 'development') {
  /*router.addRoutes([
    {
      path: "/admin/mappings",
      name: "admin-mappings",
      component: () => import("../views/AdminMappings.vue")
    }
  ]);*/
}
// Must be last (fall-back)
router.addRoute({ path: '*', name: '404', component: () => import("../views/NotFound.vue") });

function createMetaTag(name, value) {
  const tag = document.createElement('meta');
  tag.setAttribute(name.startsWith('og:') ? 'property' : 'name', name);
  tag.setAttribute('content', value);
  tag.setAttribute('data-vue-router-controlled', '');
  return tag;
}

function createSchemaTag(value) {
  const tag = document.createElement('script');
  tag.setAttribute('type', 'application/ld+json');
  tag.setAttribute('data-vue-router-controlled', '');
  tag.innerHTML = value;
  return tag;
}

import profileService from "@/services/profileService";

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAuthInApp = to.matched.some(record => record.meta.requiresAuthInApp);
  if (requiresAuth || (requiresAuthInApp && to.query.view == 'app')) {
    const auth = getAuth();
    onAuthStateChanged(auth, async user => {
      //console.log('FIREBASE requiresAuth', store.getters.user.localToken, store.getters.localToken);
      if (!user && store.getters.localToken) {
        // local token authorized
        // note: this token might have expired and we get 401 errors
        return;
      }
      if (!user){
        if (window.$cookies && window.$cookies.isKey("appAuthToken")) {
          // Switch the auth header which was passed in for a custom token so we can sign in to the web view
          var cookie = window.$cookies.get('appAuthToken');
          var tokenResponse = await profileService.createAuthToken(cookie);
          var response = await signInWithCustomToken(auth, tokenResponse.data.token);
          if (response.user) {
            console.log('FIREBASE response', response);
            // we have now  logged in, we are okay
            return;
          }
        }

        next({name:'login', query: to.query });// keep query
        return;
      }
    });
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithDescription = to.matched.slice().reverse().find(r => r.meta && r.meta.description);
  const nearestWithImage = to.matched.slice().reverse().find(r => r.meta && r.meta.image);

  // Find the nearest route element with meta tags.
  //const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  var title = nearestWithTitle ? nearestWithTitle.meta.title : tenant.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
/*
  var metaTags = nearestWithMeta ? nearestWithMeta.meta.metaTags : [];

  // Turn the meta tag definitions into actual elements in the head.
  var tags = metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  });*/
  var description = null;
  if (nearestWithDescription) {
    description = nearestWithDescription.meta.description;
  }
  var image = null;
  if (nearestWithImage) {
    image = nearestWithImage.meta.image;
  }
  // Add the meta tags to the document head.
  //tags.forEach(tag => document.head.appendChild(tag));

  var meta = {
    title: title,
    description: description,
    image: image,
  };
  EventBus.$emit('page-header-change', meta);


  // store it for components to use
  //store.commit('updatePageMeta', meta);
  next();
});

router.afterEach((to, from) => {
  //store.commit('updateSearchExpanded', false);
  const enableCustomTrackersTo = to.matched.some(record => record.meta.enableCustomTrackers);
  const enableCustomTrackersFrom = from.matched.some(record => record.meta.enableCustomTrackers);
  if (!enableCustomTrackersTo) {
    router.app.$analytics.disableCustomTrackers(router.app);
  }
  //else ()
  //console.log('afterEach', router.app.$analytics, enableCustomTrackers);
})

EventBus.$on('page-schema-change', data => {
  Array.from(document.querySelectorAll('[data-vue-router-controlled][type="application/ld+json"]')).map(el => el.parentNode.removeChild(el));
  document.head.appendChild(createSchemaTag(JSON.stringify(data)))
});

EventBus.$on('page-header-change', data => {
  //console.log('page-header-change', data);
  // store it for components to use when the component is created after the data is broadcasted
  // the share component will read if from the store and take it from there
  store.commit('updatePageMeta', data);
  document.title = data.title;
  Array.from(document.querySelectorAll('[data-vue-router-controlled][property="og:title"]')).map(el => el.parentNode.removeChild(el));
  document.head.appendChild(createMetaTag('og:title', data.title))

  Array.from(document.querySelectorAll('[data-vue-router-controlled][name="description"]')).map(el => el.parentNode.removeChild(el));
  Array.from(document.querySelectorAll('[data-vue-router-controlled][property="og:description"]')).map(el => el.parentNode.removeChild(el));
  if (data.description) {
    document.head.appendChild(createMetaTag('description', data.description))
    document.head.appendChild(createMetaTag('og:description', data.description))
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled][property="og:image"]')).map(el => el.parentNode.removeChild(el));
  if (data.image) {
    document.head.appendChild(createMetaTag('og:image', data.image))
  }
});

export default router;
